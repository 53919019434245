/* You can add global styles to this file, and also import other style files */
$secondary: #cf0a2c;
$inputColor: #969696;
$maxMobileWidth: 959px;
@font-face {
  font-family: "Din Next LT Pro";
  src: url(/assets/fonts/DINNextLTPro-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Din Next LT Pro Bold";
  src: url(/assets/fonts/DINNextLTPro-Bold.otf) format("opentype");
}

@font-face {
  font-family: "Din Next LT Pro Medium";
  src: url(/assets/fonts/DINNextLTPro-Medium.otf) format("opentype");
}

@font-face {
  font-family: "Din Next LT Pro Light";
  src: url(/assets/fonts/DINNextLTPro-Light.otf) format("opentype");
}
html, body { height: 100%; }
body { margin: 0; }
.mat-raised-button.mat-primary:hover {
  background-color: #4b4b4b;
}
.mat-raised-button.mat-accent:hover {
  background-color: #FF5779;
}
.navigation-tabs .mat-tab-link {
  color: black;
  opacity: 1;
  font-size: 14px;
  font-family: "Din Next LT Pro Medium", "sans-serif";
  min-width: initial;
  padding: 0 25px;
  margin: 0;
  height: 30px;

  &:hover {
    background-color: #F9DCE1;
    &:active {
      background-color: transparent;
    }
  }
  &:active {
    background-color: transparent;
  }
}

.navigation-tabs.page-links .mat-tab-link {
  &:hover {
    background-color: #4B4B4B;
  }
}

.mat-tab-nav-bar.page-links, .mat-tab-header.page-links {
  border-bottom: none;
}

.mat-menu-panel {
  background-color: white;
  .mat-menu-item {
    color: black;
    font-family: "Din Next LT Pro Light", "sans-serif";
    text-transform: none;
    letter-spacing: initial;
    padding-right: 50px;
  }
  .mat-menu-item:hover {
    color: $secondary;
  }
}

body .mat-card {
  color: black;
  background-color: white;
  border-radius: 0;
  padding: 40px;
}
body .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0 2px 23px 0 rgba(0,0,0,0.5);
  color: black;
  background-color: white;
}
body .mat-card.thank-you-card:not([class*=mat-elevation-z]) {
  color: white;
  background-color: $secondary;
  margin-top: 25px;
  .thank-you-heading {
    font-family: "Din Next LT Pro Bold", "sans-serif";
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 12px;
  }
  .text {
    font-family: "Din Next LT Pro", "sans-serif";
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.26px;
    margin-bottom: 60px;
    width: 60%;
  }
}
body .contact-info .get-in-touch-form .mat-card.thank-you-card:not([class*=mat-elevation-z]) {
  margin-top: 0;
  padding: 20px;
  .text {
    margin-bottom: 0;
    width: initial;
  }
  .close-icon {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}

body .mat-form-field {
  .mat-input-element {
    color: black;
  }
  .mat-form-field-label {
    color: $inputColor;
  }
  .mat-form-field-underline {
    background-color: $inputColor;
  }
  .mat-form-field-ripple {
    background-color: $inputColor;
  }
  .mat-form-field-required-marker {
    color: $inputColor;
  }
  .mat-hint {
    color: $inputColor;
  }
}

body .contact-us-card .mat-form-field {
  margin-bottom: 20px;
  margin-right: 20px;
}

body .get-in-touch-form .mat-form-field {
  .mat-input-element {
    color: white;
    caret-color: white;
  }
  .mat-form-field-label {
    color: white;
  }
  .mat-form-field-underline {
    background-color: $secondary;
  }
  .mat-form-field-ripple {
    background-color: $secondary;
  }
  .mat-form-field-required-marker {
    color: white;
  }
  .mat-hint {
    color: white;
  }
}

.mat-tab-nav-bar.mat-primary.navigation-tabs .mat-ink-bar, .mat-tab-group.mat-primary.image-gallery .mat-ink-bar {
  min-height: 5px;
  background-color: $secondary;
}
.mat-tab-group.mat-primary.image-gallery.landing-page-taglines .mat-ink-bar {
  min-height: 0;
  height: 0;
  background-color: white;
}
.contact-info {
  .mat-tab-nav-bar.mat-primary.navigation-tabs .mat-ink-bar, .mat-tab-group.mat-primary.image-gallery .mat-ink-bar {
    min-height: 5px;
    background-color: white;
  }
  .mat-tab-links {
    justify-content: center;
  }
}

.image-gallery .mat-tab-label, .image-gallery .mat-tab-label img {
  padding: 0;
}

.image-gallery .mat-tab-label {
  padding: 0;
  min-width: initial;
  margin-right: 20px;
  height: 100px;
}
.image-gallery .mat-tab-label:last-of-type {
  margin-right: 0;
}

.image-gallery .mat-tab-label img.label-image {
  margin-top: 16px;
  height: 80px;
}
.image-gallery .full-image {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 15px;
}

.mat-tab-header-pagination-after .mat-tab-header-pagination-chevron,
.mat-tab-header-pagination-before .mat-tab-header-pagination-chevron {
  border-color: black;
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}

.full-width {
  width: 100%;
}
button, button.mat-raised-button, button.mat-raised-button:not([class*=mat-elevation-z]) {
  text-transform: uppercase;
  border-radius: 0;
  box-shadow: none;
  color: white;
  font-size: 12px;
  line-height: 12px;
  font-family: "Din Next LT Pro Bold", "sans-serif";
  padding: 15px 35px;
  letter-spacing: 2px;
}

.description-text {
  font-size: 19px;
  line-height: 29px;
  font-family: "Din Next LT Pro Light", "sans-serif";
}

@media (max-width: 959px) {
  .project-type {
    margin-top: 50px;
  }
}

.project-type.thin {
  margin-top: 120px;
  background-color: #e8e8e8;
  a {
    font-family: "Din Next LT Pro Bold", "sans-serif";
    text-decoration: none;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.86px;
    color: black;
    display: block;
    margin-bottom: 20%;
    margin-left: 8%;
  }
  a:hover {
    color: $secondary;
  }
  a:active {
    color: black;
  }
  .project-type-description {
  }
  h2 {
    margin-top: 15%;
    margin-bottom: 0;
    margin-left: 8%;
  }
  .description-text {
    margin-left: 8%;
    margin-right: 10%;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  img {
    filter: grayscale(100%) opacity(50%);
  }
  &:hover img {
    filter: none;
  }
}
.project-type {
  margin-top: 120px;
  background-color: #e8e8e8;
  a {
    font-family: "Din Next LT Pro Bold", "sans-serif";
    text-decoration: none;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.86px;
    color: black;
    display: block;
    margin-bottom: 20%;
    margin-left: 15%;
  }
  a:hover {
    color: $secondary;
  }
  a:active {
    color: black;
  }
  .project-type-description {
  }
  h2 {
    margin-top: 15%;
    margin-bottom: 0;
    margin-left: 15%;
  }
  .description-text {
    margin-left: 15%;
    margin-right: 10%;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  img {
    filter: grayscale(100%) opacity(50%);
  }
  &:hover img {
    filter: none;
  }
}

.container {
  margin-left: 40px;
  margin-right: 40px;
}

.mat-tab-label-active .dot {
  background-color: black;
}

.image-gallery.landing-page-taglines .mat-tab-label {
  height: 12px;
}

body vg-player {
  height: auto;
  margin-top: 120px;
}
queblo-youtube-player {
  width: 100%;
}
youtube-player {
  width: 100%;

  iframe {
    width: 100%;
    min-height: 60vw;
  }
}

@media (max-width: $maxMobileWidth) {
  .project-type {
    margin-top: 50px;
  }
  .project-type.thin {
    h2 {
      margin-top: 8%;
      margin-bottom: 8%;
    }
    a {
      margin-bottom: 8%;
      margin-top: 8%;
    }
  }
  .gallery-container {
    mat-tab-header {
      display: none;
    }
  }
}

