@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$custom-typography: mat-typography-config(
  $font-family: '"Din Next LT Pro", sans-serif',
  $headline: mat-typography-level(51px, 51px, 400, '"Din Next LT Pro Medium", sans-serif'),
  $title: mat-typography-level(28px, 28px, 400, '"Din Next LT Pro Medium", sans-serif', "normal"),
  $subheading-2: mat-typography-level(24px, 24px, 400, '"Din Next LT Pro", sans-serif'),
  $body-2: mat-typography-level(12px)
);
@include mat-core($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mat-primary: (
  main: #000000,
  lighter: #b3b3b3,
  darker: #b3b3b3,
  200: #b3b3b3, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$mat-secondary: (
  main: #cf0a2c,
  lighter: #FF5779,
  darker: #FF5779,
  200: #cf0a2c, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$queblo-app-primary: mat-palette($mat-primary, main, lighter, darker);
$queblo-app-secondary:  mat-palette($mat-secondary, main, lighter, darker);

// The warn palette is optional (defaults to red).
$queblo-app-warn:    mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$queblo-app-theme: mat-dark-theme((
  color: (
    primary: $queblo-app-primary,
    accent: $queblo-app-secondary,
    warn: $queblo-app-warn,
  )
));

@include mat-button-typography($custom-typography);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($queblo-app-theme);

button.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

button.mat-accent .mat-ripple-element {
  background-color: #61051477 !important;
}

@include mat-ripple-theme($queblo-app-theme);
